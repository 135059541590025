import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-3.css';

const PageThree = forwardRef((props, ref) => (
  <>
    <SEO title="Page 3" />
    <main className="page page3">
      <div className="page__image-container">
        <div className="page__image-border-container">
          <StaticImage
            alt="The little creature named Jimbugs stuck inside a sock"
            aspectRatio={3203 / 4118}
            className="page__image"
            height={750}
            layout="constrained"
            loading="eager"
            objectFit="contain"
            placeholder="tracedSVG"
            src="../../images/page-3.png"
          />
        </div>
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            Just then a bright red sock jumped up to the top of the laundry! It had two little legs
            sticking out of the end. These little legs were madly shaking about and a voice was
            coming from inside the sock.
          </p>
          <p>
            <strong>&ldquo;Let me out, let me out!&rdquo;</strong>
          </p>
          <p>
            &ldquo;Who are you?&rdquo; said Amanda. She was really scared.
          </p>
          <p>
            &ldquo;I&apos;m just a little Sockpicker named Jimbugs. Please let me out.&rdquo; a trembling
            voice replied.
          </p>
          <p>
            &ldquo;What are you doing inside my sock?&rdquo; asked Amanda, nervously.
          </p>
          <p>
            &ldquo;Please, just let me out. It&apos;s hard to talk with my head in a sock.&rdquo; Jimbugs was
            beginning to cry.
          </p>
          <p>
            &ldquo;All right,&rdquo; said Amanda, &ldquo;but promise not to hurt me if I let you out.&rdquo;
          </p>
          <p>
            &ldquo;I promise.&rdquo; cried the little voice.
          </p>
        </div>
      </div>
      <PreviousPage to="/page-2" />
      <NextPage to="/page-4" />
    </main>
  </>
));

const PageThreeContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageThree ref={textRef} />;
};

export default PageThreeContainer;
